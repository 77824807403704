import { render, staticRenderFns } from "./update-time-based-restrictions-of-facility-reservation-activity-dialog.vue?vue&type=template&id=aca278a2&"
import script from "./update-time-based-restrictions-of-facility-reservation-activity-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./update-time-based-restrictions-of-facility-reservation-activity-dialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports