import { Command, Query } from '@/application/types';
import { ReservationDisplayNameType, CalendarViewPreference, FacilityBlockerId, FacilityId, FacilityReservationActivityId, FacilityReservationId, HorseId, RidingLessonId, RidingLessonRegistrationId, RidingLessonRegistrationType, RidingLessonTypeId, Time, TimeFrame, UserId, Moment } from '@/types';
import { BlurHashImageReadModel } from '@/helpers';

// -- Models

// Lowercase as this is how they are named by Vuetify.
export enum CalendarRangeType {
  week = 'week',
  day = 'day',
}

export enum CalendarType {
  FACILITY_CALENDAR = 'FACILITY_CALENDAR',
  RIDING_LESSON_CALENDAR = 'RIDING_LESSON_CALENDAR',
}
type CalendarEventType = 'FACILITY_RESERVATION' | 'FACILITY_BLOCKER' | 'RIDING_LESSON';

export interface Facility {
  facilityId: FacilityId;
  name: string;
  openingHours: TimeFrame;
  spaces: number;
  facilityReservationConfiguration: FacilityReservationConfiguration | null;
  facilityReservationActivities: FacilityReservationActivity[];
}

export interface FacilityReservationConfiguration {
  timeOptionInterval: number;
  bookingInAdvance: number;
  timeToGrantAccessForNewDayInAdvance: Time;
  reservationDisplayNameType: ReservationDisplayNameType;
  bookingNotice: string | null;
  wasBookingNoticeDismissed: boolean;
}

export interface FacilityReservationActivity {
  facilityReservationActivityId: FacilityReservationActivityId;
  name: string;
  minTimeOptionIntervals: number;
  maxTimeOptionIntervals: number;
  requiredSpaces: number;
  color: string;
}

export interface RidingLessonType {
  ridingLessonTypeId: RidingLessonTypeId;
  name: string;
  registrationInAdvanceInDays: number;
  requiredSpaces: number;
}

export interface User {
  userId: UserId;
  name: string;
}

export interface Horse {
  horseId: HorseId;
  name: string;
}

export interface CalendarEvent {
  id: FacilityReservationId | FacilityBlockerId | RidingLessonId;
  type: CalendarEventType;
  from: Moment;
  to: Moment;
  start: string;
  end: string;
}

export interface FacilityReservationCalendarEvent extends CalendarEvent {
  type: 'FACILITY_RESERVATION';
  facilityReservationActivity: FacilityReservationActivity;
  name: string;
  horse: HorseWithImage;
  comment: string | null;
  reservationDisplayNameType: ReservationDisplayNameType;
  userName: string;
  horseName: string;
  hasAccessToHorse: boolean;
  isUpdatableForUser: boolean;
  isDeletableForUser: boolean;
  createdAt: Moment;
}

export type HorseWithImage = {
  horseId: HorseId;
  name: string;
  image: BlurHashImageReadModel | null;
}

export interface FacilityBlockerCalendarEvent extends CalendarEvent {
  type: 'FACILITY_BLOCKER';
  blocksSpaces: number | null;
  reason: string | null;
  description: string | null;
}

export interface RidingLessonCalendarEvent extends CalendarEvent {
  type: 'RIDING_LESSON';
  facilityName: string;
  ridingLessonType: RidingLessonType;
  lastRegistrationAt: Moment;
  maxAmountOfParticipants: number | null;
  isRegistrationForOneselfAllowed: boolean;
  isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
  explanationForCommentFieldForRegistration: string | null;
  isWaitingListAvailable: boolean;
  waitingListNotice: string | null;
  ridingInstructor: User | null;
  horses: Horse[];
  registrations: RidingLessonRegistration[];
}

export interface RidingLessonRegistration {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  user: User;
  type: RidingLessonRegistrationType;
  horse: HorseWithImage | null;
  nameOfAnotherPerson: string | null;
  comment: string | null;
  registeredAt: Moment;
}

export interface FacilityIdentification {
  facilityId: FacilityId;
  name: string;
}

export interface GetRidingLessonsAsCalendarEventsResponse {
  timeFrame: TimeFrame;
  calendarEvents: RidingLessonCalendarEvent[];
}

export interface PreferredIntervalsForFacilityReservationActivity {
  facilityReservationActivityId: FacilityReservationActivityId;
  preferredIntervalsForFarm: number | null;
  preferredIntervalsForUser: number | null;
}

// -- Commands

export interface ReserveFacilityCommand extends Command {
  facilityId: FacilityId;
  facilityReservationActivityId: FacilityReservationActivityId;
  horseId: HorseId;
  from: Moment;
  to: Moment;
  comment: string | null;
}

export interface UpdateFacilityReservationCommand extends Command {
  facilityReservationId: FacilityReservationId;
  facilityReservationActivityId: FacilityReservationActivityId;
  from: Moment;
  to: Moment;
  comment: string | null;
}

export interface CancelFacilityReservationCommand extends Command {
  facilityReservationId: FacilityReservationId;
}

export interface DismissBookingNoticeCommand extends Command {
  facilityId: FacilityId;
}

export interface RegisterForRidingLessonCommand extends Command {
  ridingLessonId: RidingLessonId;
  type: RidingLessonRegistrationType;
  horseId: HorseId | null;
  nameOfAnotherPerson: string | null;
  comment: string | null;
}

export interface CancelRidingLessonRegistrationCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
}

export interface UpdatePreferredCalendarViewCommand extends Command {
  calendarViewPreference: CalendarViewPreference;
}

// -- Queries

export interface GetCalendarEventsForFacilityQuery extends Query {
  facilityId: FacilityId;
  rangeFrom: Moment;
  rangeTo: Moment;
}

export interface GetRidingLessonsAsCalendarEventsQuery extends Query {
  rangeFrom: Moment;
  rangeTo: Moment;
}
