import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { UserRole, FarmManagerPermission } from '@/types';
import Horses from './components/horses.vue';
import HorseDetailsLoadingWrapper from './components/horse-details-loading-wrapper.vue';

const defaultMeta = {
  title: 'Pferde',
  accessibleFor: RouteAccessibility.AUTHENTICATED,
  requiresRole: UserRole.ROLE_FARM_MANAGER,
  requiresPermission: FarmManagerPermission.HORSES_READ,
};

export const horseManagementRoutes: RouteConfig[] = [
  {
    path: 'pferde',
    component: {
      template: '<router-view />',
    },
    meta: defaultMeta,
    children: [
      {
        name: 'horse-management-horses',
        path: '',
        component: Horses,
        meta: defaultMeta,
      },
      {
        name: 'horse-management-horse-details',
        path: 'pferd/:horseId',
        component: HorseDetailsLoadingWrapper,
        meta: {
          ...defaultMeta,
          routeNameForBackButton: 'horse-management-horses',
        },
      },
    ],
  },
];
