import { FarmAndUserProperties, FarmAndUserPropertiesForCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure/api-requests';
import { FileResponse } from '@/application/types';
import { DeleteHorseAsManagerWorkflow, UpdateHorseNameAsManagerWorkflow, GetBoxSignAsManagerQuery, BoxSignGenerationProcessForAllHorses, DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand, GetBoxSignsForAllHorsesAsManagerQuery, GetHorsesAsManagerQuery, HorsesResponse, GetHorseAsManagerQuery, Horse, UpdateEquineNumberAsManagerCommand, GetDocumentsForHorseAsManagerQuery, HorseDocument, GetDocumentPDFAsManagerQuery, DeleteHorseDocumentAsManagerCommand, CreateHorseDocumentAsManagerCommand, ArchiveHorseAsManagerWorkflow, GetActiveGerblhofFeedingForHorseAsManagerQuery, GerblhofFeeding, GetMedicalRecordsForHorseAsManagerQuery, MedicalRecords } from './types';

// -- Queries

export function getHorses(query: GetHorsesAsManagerQuery & FarmAndUserProperties): Promise<HorsesResponse> {
  const url = `${apiUrl}/api/horse-management/get-horses-as-manager-query`;
  return performApiRequest<HorsesResponse>({ url, method: 'POST', data: query });
}

export function getHorse(query: GetHorseAsManagerQuery & FarmAndUserProperties): Promise<Horse> {
  const url = `${apiUrl}/api/horse-management/get-horse-as-manager-query`;
  return performApiRequest<Horse>({ url, method: 'POST', data: query });
}

export function getActiveGerblhofFeedingForHorse(
  query: GetActiveGerblhofFeedingForHorseAsManagerQuery & FarmAndUserProperties
): Promise<GerblhofFeeding[]> {
  const url = `${apiUrl}/api/horse-management/get-active-gerblhof-feeding-for-horse-as-manager-query`;
  return performApiRequest<GerblhofFeeding[]>({ url, method: 'POST', data: query });
}

export function getBoxSign(query: GetBoxSignAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getBoxSignGenerationProcessesForAllHorses(query: FarmAndUserProperties): Promise<BoxSignGenerationProcessForAllHorses[]> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-generation-processes-for-all-horses-as-manager-query`;
  return performApiRequest<BoxSignGenerationProcessForAllHorses[]>({ url, method: 'POST', data: query });
}

export function getBoxSignsForAllHorses(query: GetBoxSignsForAllHorsesAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-signs-for-all-horses-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getContactListForHorsesAsPDF(query: FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-contact-list-for-horses-as-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getDocumentsForHorse(query: GetDocumentsForHorseAsManagerQuery & FarmAndUserProperties): Promise<HorseDocument[]> {
  const url = `${apiUrl}/api/horse-management/get-documents-for-horse-as-manager-query`;
  return performApiRequest<HorseDocument[]>({ url, method: 'POST', data: query });
}

export function getDocumentPDF(query: GetDocumentPDFAsManagerQuery & FarmAndUserProperties): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-document-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: query });
}

export function getMedicalRecordsForHorse(query: GetMedicalRecordsForHorseAsManagerQuery & FarmAndUserProperties): Promise<MedicalRecords> {
  const url = `${apiUrl}/api/medical-records/get-medical-records-for-horse-as-manager-query`;
  return performApiRequest<MedicalRecords>({ url, method: 'POST', data: query });
}

// -- Commands

export function updateHorseName(command: UpdateHorseNameAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-horse-name-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteHorseAsFarmManager(command: DeleteHorseAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function generateBoxSignsForAllHorses(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/generate-box-signs-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteBoxSignGenerationProcessForAllHorses(
  command: DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand & FarmAndUserProperties
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-box-sign-generation-process-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateEquineNumber(command: UpdateEquineNumberAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-equine-number-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createHorseDocument(
  command: CreateHorseDocumentAsManagerCommand & FarmAndUserPropertiesForCommandWithFiles
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/create-horse-document-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: command });
}

export function deleteHorseDocument(command: DeleteHorseDocumentAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-document-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}

export function archiveHorse(command: ArchiveHorseAsManagerWorkflow & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/horse-management/archive-horse-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: command });
}
