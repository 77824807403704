import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useAppStore } from '@/application/app/store';

export function initKeyboardClassesToggle(): void {
  // Old versions of the mobile app doesn't have the Keyboard plugin yet.
  if (Capacitor.isPluginAvailable('Keyboard')) {
    const appStore = useAppStore();
    Keyboard.addListener('keyboardDidShow', () => {
      appStore.updateVirtualKeyboardVisibility(true);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      appStore.updateVirtualKeyboardVisibility(false);
    });
  }
}
