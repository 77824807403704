export interface DataListItem {
  key: string;
  label: string;
  value: string;
  customTemplateValue?: any;
  meta?: any;
}

export enum DialogWidth {
  small = 500,
  smallNested = 460,
  medium = 650,
  large = 800,
  largeNested = 760,
}
