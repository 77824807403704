import { render, staticRenderFns } from "./update-gerblhof-feeding-dialog.vue?vue&type=template&id=e1cc3c20&scoped=true&"
import script from "./update-gerblhof-feeding-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./update-gerblhof-feeding-dialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./update-gerblhof-feeding-dialog.vue?vue&type=style&index=0&id=e1cc3c20&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1cc3c20",
  null
  
)

export default component.exports