import { apiUrl } from '@/infrastructure/api-url-service';
import { performApiRequest } from '@/infrastructure/api-requests';
import { FarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { CreateStationAsManagerCommand, DeleteStationAsManagerCommand, FeedProtocolConfiguration, FeedProtocolOverview, PauseMonitoringForHorseAsManagerCommand, StationUtilization, UnpauseMonitoringForHorseAsManagerCommand, UpdateStationAsManagerCommand } from './types';

// -- Queries

export function getFeedProtocolOverview(query: FarmAndUserProperties): Promise<FeedProtocolOverview> {
  const url = `${apiUrl}/api/feed-protocol/get-feed-protocol-overview-as-manager-query`;
  return performApiRequest<FeedProtocolOverview>({ url, method: 'POST', data: query });
}

export function getStationUtilization(query: FarmAndUserProperties): Promise<StationUtilization> {
  const url = `${apiUrl}/api/feed-protocol/get-station-utilization-as-manager-query`;
  return performApiRequest<StationUtilization>({ url, method: 'POST', data: query });
}

export function getFeedProtocolConfiguration(query: FarmAndUserProperties): Promise<FeedProtocolConfiguration> {
  const url = `${apiUrl}/api/feed-protocol/get-feed-protocol-configuration-as-manager-query`;
  return performApiRequest<FeedProtocolConfiguration>({ url, method: 'POST', data: query });
}

// -- Commands

export function pauseMonitoringForHorse(command: PauseMonitoringForHorseAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/pause-monitoring-for-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function unpauseMonitoringForHorse(command: UnpauseMonitoringForHorseAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/unpause-monitoring-for-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function enablePushNotifications(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/enable-push-notifications-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function disablePushNotifications(command: FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/disable-push-notifications-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function createStation(command: CreateStationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/create-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function updateStation(command: UpdateStationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/update-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}

export function deleteStation(command: DeleteStationAsManagerCommand & FarmAndUserProperties): Promise<void> {
  const url = `${apiUrl}/api/feed-protocol/delete-station-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: command });
}
