import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus, FileResponse } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { HorseId } from '@/types';
import { ArchiveHorseAsManagerWorkflow, BoxSignGenerationProcessForAllHorses, CreateHorseDocumentAsManagerCommand, DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand, DeleteHorseAsManagerWorkflow, DeleteHorseDocumentAsManagerCommand, GerblhofFeeding, GetActiveGerblhofFeedingForHorseAsManagerQuery, GetBoxSignAsManagerQuery, GetBoxSignsForAllHorsesAsManagerQuery, GetDocumentPDFAsManagerQuery, GetDocumentsForHorseAsManagerQuery, GetHorseAsManagerQuery, GetHorsesAsManagerQuery, GetMedicalRecordsForHorseAsManagerQuery, Horse, HorseDocument, HorseForList, MedicalRecords, UpdateEquineNumberAsManagerCommand, UpdateHorseNameAsManagerWorkflow } from './types';
import { deleteBoxSignGenerationProcessForAllHorses, deleteHorseAsFarmManager, generateBoxSignsForAllHorses, getBoxSign, getBoxSignGenerationProcessesForAllHorses, getBoxSignsForAllHorses, getContactListForHorsesAsPDF, getHorse, getHorses, updateHorseName, updateEquineNumber, getDocumentsForHorse, createHorseDocument, deleteHorseDocument, getDocumentPDF, archiveHorse, getActiveGerblhofFeedingForHorse, getMedicalRecordsForHorse } from './service';

interface HorseManagementState {
  searchForHorses: string | null;
  limitForHorses: number;
  totalCountOfHorses: number | null;
  horses: HorseForList[] | null;

  currentHorse: Horse | null;
  idOfCurrentHorse: HorseId | null;
  documentsForCurrentHorse: HorseDocument[] | null;
  medicalRecordsForCurrentHorse: MedicalRecords | null;

  activeGerblhofFeedingForHorse: GerblhofFeeding[] | null;

  boxSignGenerationProcessesForAllHorses: BoxSignGenerationProcessForAllHorses[];

  getHorsesStatus: ActionStatus;
  getHorseStatus: ActionStatus;
  updateHorseNameStatus: ActionStatus;
  deleteHorseStatus: ActionStatus;
  getBoxSignStatus: ActionStatus;
  generateBoxSignsForAllHorsesStatus: ActionStatus;
  getBoxSignGenerationProcessesForAllHorsesStatus: ActionStatus;
  getBoxSignsForAllHorsesStatus: ActionStatus;
  deleteBoxSignGenerationProcessForAllHorsesStatus: ActionStatus;
  getContactListForHorsesAsPDFStatus: ActionStatus;
  updateEquineNumberStatus: ActionStatus;
  getDocumentsForHorseStatus: ActionStatus;
  createHorseDocumentStatus: ActionStatus;
  getDocumentPDFStatus: ActionStatus;
  deleteHorseDocumentStatus: ActionStatus;
  archiveHorseStatus: ActionStatus;
  getActiveGerblhofFeedingForHorseStatus: ActionStatus;
  getMedicalRecordsForHorseStatus: ActionStatus;
}

function initialState(): HorseManagementState {
  return {
    searchForHorses: null,
    limitForHorses: 50,
    totalCountOfHorses: null,
    horses: null,
    boxSignGenerationProcessesForAllHorses: [],

    currentHorse: null,
    idOfCurrentHorse: null,
    documentsForCurrentHorse: null,
    medicalRecordsForCurrentHorse: null,

    activeGerblhofFeedingForHorse: null,

    getHorsesStatus: ActionStatus.None,
    getHorseStatus: ActionStatus.None,
    updateHorseNameStatus: ActionStatus.None,
    deleteHorseStatus: ActionStatus.None,
    getBoxSignStatus: ActionStatus.None,
    generateBoxSignsForAllHorsesStatus: ActionStatus.None,
    getBoxSignGenerationProcessesForAllHorsesStatus: ActionStatus.None,
    getBoxSignsForAllHorsesStatus: ActionStatus.None,
    deleteBoxSignGenerationProcessForAllHorsesStatus: ActionStatus.None,
    getContactListForHorsesAsPDFStatus: ActionStatus.None,
    updateEquineNumberStatus: ActionStatus.None,
    getDocumentsForHorseStatus: ActionStatus.None,
    createHorseDocumentStatus: ActionStatus.None,
    getDocumentPDFStatus: ActionStatus.None,
    deleteHorseDocumentStatus: ActionStatus.None,
    archiveHorseStatus: ActionStatus.None,
    getActiveGerblhofFeedingForHorseStatus: ActionStatus.None,
    getMedicalRecordsForHorseStatus: ActionStatus.None,
  };
}

export const useHorseManagementStore = defineStore('horseManagement', {
  state: (): HorseManagementState => initialState(),
  getters: {
    isGetHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getHorsesStatus === ActionStatus.InProgress,
    isGetHorseProcessing: (state: HorseManagementState): boolean =>
      state.getHorseStatus === ActionStatus.InProgress,
    isUpdateHorseNameProcessing: (state: HorseManagementState): boolean =>
      state.updateHorseNameStatus === ActionStatus.InProgress,
    isDeleteHorseProcessing: (state: HorseManagementState): boolean =>
      state.deleteHorseStatus === ActionStatus.InProgress,
    isGetBoxSignProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignStatus === ActionStatus.InProgress,
    isGenerateBoxSignsForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.generateBoxSignsForAllHorsesStatus === ActionStatus.InProgress,
    isGetBoxSignGenerationProcessesForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignGenerationProcessesForAllHorsesStatus === ActionStatus.InProgress,
    isGetBoxSignsForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.getBoxSignsForAllHorsesStatus === ActionStatus.InProgress,
    isDeleteBoxSignGenerationProcessForAllHorsesProcessing: (state: HorseManagementState): boolean =>
      state.deleteBoxSignGenerationProcessForAllHorsesStatus === ActionStatus.InProgress,
    isGetContactListForHorsesAsPDFProcessing: (state: HorseManagementState): boolean =>
      state.getContactListForHorsesAsPDFStatus === ActionStatus.InProgress,
    isUpdateEquineNumberProcessing: (state: HorseManagementState): boolean =>
      state.updateEquineNumberStatus === ActionStatus.InProgress,
    isGetDocumentsForHorseProcessing: (state: HorseManagementState): boolean =>
      state.getDocumentsForHorseStatus === ActionStatus.InProgress,
    isCreateHorseDocumentProcessing: (state: HorseManagementState): boolean =>
      state.createHorseDocumentStatus === ActionStatus.InProgress,
    isGetDocumentPDFProcessing: (state: HorseManagementState): boolean =>
      state.getDocumentPDFStatus === ActionStatus.InProgress,
    isDeleteHorseDocumentProcessing: (state: HorseManagementState): boolean =>
      state.deleteHorseDocumentStatus === ActionStatus.InProgress,
    isArchiveHorseProcessing: (state: HorseManagementState): boolean =>
      state.archiveHorseStatus === ActionStatus.InProgress,
    isGetActiveGerblhofFeedingForHorseProcessing: (state: HorseManagementState): boolean =>
      state.getActiveGerblhofFeedingForHorseStatus === ActionStatus.InProgress,
    isGetMedicalRecordsForHorseProcessing: (state: HorseManagementState): boolean =>
      state.getMedicalRecordsForHorseStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateSearchForHorses(search: string | null): Promise<void> {
      this.searchForHorses = search;

      return this.getHorses();
    },

    increaseLimitForHorse(): Promise<void> {
      this.limitForHorses += 50;

      return this.getHorses();
    },

    updateCurrentHorse(horseId: HorseId): Promise<void> {
      this.idOfCurrentHorse = horseId;

      return this.getHorse();
    },

    resetCurrentHorse(): Promise<void> {
      this.idOfCurrentHorse = null;
      this.currentHorse = null;
      this.activeGerblhofFeedingForHorse = null;
      this.medicalRecordsForCurrentHorse = null;

      return Promise.resolve();
    },

    resetDocumentsForCurrentHorse(): Promise<void> {
      this.documentsForCurrentHorse = null;

      return Promise.resolve();
    },

    // -- Queries

    getHorses(): Promise<void> {
      const query: GetHorsesAsManagerQuery = {
        search: this.searchForHorses,
        limit: this.limitForHorses,
      };
      const { getHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorsesStatus,
        () => getHorses(attachFarmAndUserProperties(query))
          .then((response) => {
            this.horses = response.horses;
            this.totalCountOfHorses = response.totalCount;
          })
      );
    },

    getHorse(): Promise<void> {
      const query: GetHorseAsManagerQuery = {
        horseId: this.idOfCurrentHorse!,
      };
      const { getHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorseStatus,
        () => getHorse(attachFarmAndUserProperties(query))
          .then((horse) => {
            this.currentHorse = horse;
          })
      );
    },

    getBoxSign(query: GetBoxSignAsManagerQuery): Promise<FileResponse> {
      const { getBoxSignStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignStatus,
        () => getBoxSign(attachFarmAndUserProperties(query))
      );
    },

    getBoxSignGenerationProcessesForAllHorses(): Promise<void> {
      const { getBoxSignGenerationProcessesForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignGenerationProcessesForAllHorsesStatus,
        () => getBoxSignGenerationProcessesForAllHorses(attachFarmAndUserProperties({}))
          .then((boxSignGenerationProcessesForAllHorses) => {
            this.boxSignGenerationProcessesForAllHorses = boxSignGenerationProcessesForAllHorses;
          })
      );
    },

    getBoxSignsForAllHorses(query: GetBoxSignsForAllHorsesAsManagerQuery): Promise<FileResponse> {
      const { getBoxSignsForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBoxSignsForAllHorsesStatus,
        () => getBoxSignsForAllHorses(attachFarmAndUserProperties(query))
      );
    },

    getContactListForHorsesAsPDF(): Promise<FileResponse> {
      const { getContactListForHorsesAsPDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getContactListForHorsesAsPDFStatus,
        () => getContactListForHorsesAsPDF(attachFarmAndUserProperties({}))
      );
    },

    getDocumentsForHorse(): Promise<void> {
      const query: GetDocumentsForHorseAsManagerQuery = {
        horseId: this.idOfCurrentHorse!,
      };
      const { getDocumentsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentsForHorseStatus,
        () => getDocumentsForHorse(attachFarmAndUserProperties(query))
          .then((documentsForCurrentHorse) => {
            this.documentsForCurrentHorse = documentsForCurrentHorse;
          })
      );
    },

    getDocumentPDF(query: GetDocumentPDFAsManagerQuery): Promise<FileResponse> {
      const { getDocumentPDFStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getDocumentPDFStatus,
        () => getDocumentPDF(attachFarmAndUserProperties(query))
      );
    },

    getActiveGerblhofFeedingForHorse(): Promise<void> {
      const query: GetActiveGerblhofFeedingForHorseAsManagerQuery = {
        horseId: this.idOfCurrentHorse!,
      };
      const { getActiveGerblhofFeedingForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getActiveGerblhofFeedingForHorseStatus,
        () => getActiveGerblhofFeedingForHorse(attachFarmAndUserProperties(query))
          .then((activeGerblhofFeedingForHorse) => {
            this.activeGerblhofFeedingForHorse = activeGerblhofFeedingForHorse;
          })
      );
    },

    getMedicalRecordsForHorse(): Promise<void> {
      const query: GetMedicalRecordsForHorseAsManagerQuery = {
        horseId: this.idOfCurrentHorse!,
      };
      const { getMedicalRecordsForHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getMedicalRecordsForHorseStatus,
        () => getMedicalRecordsForHorse(attachFarmAndUserProperties(query))
          .then((medicalRecordsForCurrentHorse) => {
            this.medicalRecordsForCurrentHorse = medicalRecordsForCurrentHorse;
          })
      );
    },

    // -- Commands

    updateHorseName(command: UpdateHorseNameAsManagerWorkflow): Promise<void> {
      const { updateHorseNameStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateHorseNameStatus,
        () => updateHorseName(attachFarmAndUserProperties(command))
      );
    },

    deleteHorse(command: DeleteHorseAsManagerWorkflow): Promise<void> {
      const { deleteHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseStatus,
        () => deleteHorseAsFarmManager(attachFarmAndUserProperties(command))
          .then(() => this.getHorses())
      );
    },

    generateBoxSignsForAllHorses(): Promise<void> {
      const { generateBoxSignsForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        generateBoxSignsForAllHorsesStatus,
        () => generateBoxSignsForAllHorses(attachFarmAndUserProperties({}))
          .then(() => this.getBoxSignGenerationProcessesForAllHorses())
      );
    },

    deleteBoxSignGenerationProcessForAllHorses(command: DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand): Promise<void> {
      const { deleteBoxSignGenerationProcessForAllHorsesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteBoxSignGenerationProcessForAllHorsesStatus,
        () => deleteBoxSignGenerationProcessForAllHorses(attachFarmAndUserProperties(command))
          .then(() => this.getBoxSignGenerationProcessesForAllHorses())
      );
    },

    updateEquineNumber(command: UpdateEquineNumberAsManagerCommand): Promise<void> {
      const { updateEquineNumberStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateEquineNumberStatus,
        () => updateEquineNumber(attachFarmAndUserProperties(command))
      );
    },

    createHorseDocument(command: CreateHorseDocumentAsManagerCommand): Promise<void> {
      const { createHorseDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createHorseDocumentStatus,
        () => createHorseDocument(attachFarmAndUserPropertiesToCommandWithFiles(command))
          .then(() => this.getDocumentsForHorse())
          .then(() => this.getHorse())
      );
    },

    deleteHorseDocument(command: DeleteHorseDocumentAsManagerCommand): Promise<void> {
      const { deleteHorseDocumentStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteHorseDocumentStatus,
        () => deleteHorseDocument(attachFarmAndUserProperties(command))
          .then(() => this.getDocumentsForHorse())
          .then(() => this.getHorse())
      );
    },

    archiveHorse(command: ArchiveHorseAsManagerWorkflow): Promise<void> {
      const { archiveHorseStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        archiveHorseStatus,
        () => archiveHorse(attachFarmAndUserProperties(command))
          .then(() => this.getHorses())
      );
    },

  },
});
