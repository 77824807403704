import { render, staticRenderFns } from "./update-default-duration-of-riding-lesson-type-dialog.vue?vue&type=template&id=228b529e&"
import script from "./update-default-duration-of-riding-lesson-type-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./update-default-duration-of-riding-lesson-type-dialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports