import { Command } from '@/application/types';
import { Color, FeedProtocolMonitoringPausedUntil, FeedProtocolStationId, HorseId, Moment } from '@/types';

// -- Models

export interface FeedProtocolOverview {
  latestSignals: LatestSignalForHorse[];
  horsesWithoutSignals: HorseWithoutSignals[];
  horsesWithoutEquineNumber: HorseWithoutEquineNumber[];
}

export interface LatestSignalForHorse {
  horseId: HorseId;
  horseName: string;
  stationName: string;
  horseLeftStationLastestAt: Moment;
  signalStatus: SignalStatus;
}

export enum SignalStatus {
  OK = 'OK',
  MONITORING_PAUSED = 'MONITORING_PAUSED',
  MONITORING_PAUSED_UNTIL_NEXT_FEEDING = 'MONITORING_PAUSED_UNTIL_NEXT_FEEDING',
  OVERDUE = 'OVERDUE',
}

export interface HorseWithoutSignals {
  horseId: HorseId;
  horseName: string;
  equineNumber: string;
}

export interface HorseWithoutEquineNumber {
  horseId: HorseId;
  horseName: string;
}

export interface StationUtilization {
  stations: StationForStationUtilization[];
  stationUtilizationMap: StationUtilizationMap;
  horses: HorseStationUtilization[];
}

export interface StationForStationUtilization {
  feedProtocolStationId: FeedProtocolStationId;
  name: string;
  color: Color;
}

export interface HorseStationUtilization {
  horseId: HorseId;
  horseName: string;
  stationUtilizationMap: StationUtilizationMap;
}

export type StationUtilizationMap = Record<string, number>;

export interface FeedProtocolConfiguration {
  stations: Station[];
  arePushNotificationsEnabled: boolean;
}

export interface Station {
  feedProtocolStationId: FeedProtocolStationId;
  number: number;
  name: string;
  color: Color;
}

// -- Commands

export interface PauseMonitoringForHorseAsManagerCommand extends Command {
  horseId: HorseId;
  pausedUntil: FeedProtocolMonitoringPausedUntil;
}

export interface UnpauseMonitoringForHorseAsManagerCommand extends Command {
  horseId: HorseId;
}

export interface CreateStationAsManagerCommand extends Command {
  number: number;
  name: string;
  color: Color;
}

export interface UpdateStationAsManagerCommand extends Command {
  feedProtocolStationId: FeedProtocolStationId;
  number: number;
  name: string;
  color: Color;
}

export interface DeleteStationAsManagerCommand extends Command {
  feedProtocolStationId: FeedProtocolStationId;
}
